import { useEffect, useState } from "react";
import { trpc } from "~/components/Utility/trpc";
import { ActiveEditorType } from "~/pages/creator/mediakit";
import { IconButton, Button, ScrollArea, TextArea } from "@withjuly/solisv2";
import { ArrowLeft, Sparkle } from "@withjuly/julycons/bold";
import { useDebounce } from "~/utils/hooks/debounce";

interface AboutEditorProps {
	onClickBack: (editor: ActiveEditorType) => void;
}

export const AboutEditor: React.FC<AboutEditorProps> = ({ onClickBack }) => {
	const { data: mediaKit } = trpc.mediaKit.get.useQuery();
	const utils = trpc.useContext();
	const updateMediaKit = trpc.mediaKit.update.useMutation({
		onSuccess: () => {
			utils.mediaKit.get.invalidate();
		},
	});

	const [about, setAbout] = useState(mediaKit?.about ?? "");
	const { value: debouncedAbout } = useDebounce(about);

	const generateAbout = trpc.mediaKit.generateAboutMe.useMutation({
		onSuccess: (data) => setAbout(data ?? about),
	});

	useEffect(() => {
		if (mediaKit?.about !== debouncedAbout) {
			updateMediaKit.mutate({
				...mediaKit,
				about: debouncedAbout,
				pronouns: mediaKit?.pronouns ?? "",
				contact: mediaKit?.contact ?? "",
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedAbout]);

	return (
		<div className="flex w-full flex-col rounded-lg">
			<div className="flex w-full items-center gap-4 px-8 py-7">
				<IconButton
					icon={ArrowLeft}
					size="sm"
					variant="secondary"
					onClick={() => onClickBack("none")}
				/>
				<p className="text-header-xl font-repro">About</p>
			</div>
			<div className="bg-stroke-secondary h-px w-full" />
			<ScrollArea className="max-h-[calc(100vh_-_152px)]">
				<div className="flex h-full  flex-col gap-6 p-8">
					<TextArea
						value={about}
						name="about"
						label="About Me"
						description={`${about.length ?? 0}/850 characters`}
						onChange={(e) => setAbout(e.target.value)}
						rows={10}
					/>
					<Button
						leadingIcon={Sparkle}
						onClick={() => generateAbout.mutate()}
						className="min-h-10"
					>
						Rewrite with AI
					</Button>
				</div>
			</ScrollArea>
		</div>
	);
};
