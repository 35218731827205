import {
	AgeDataFormSchema,
	CountryDataFormSchema,
	GenderDataFormSchema,
	TikTokMetaData,
} from "@withjuly/fabric";

import { useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { CountrySelect } from "~/components/Input";
import { trpc } from "~/components/Utility/trpc";
import { useZodForm } from "~/utils/hooks/zod-form";
import {
	User,
	GlobeHemisphereWest,
	PencilSimple,
} from "@withjuly/julycons/bold";
import { Button, Modal } from "@withjuly/solisv2";
import { ZodInput } from "~/components/Input/ZodInput";

interface TikTokDataEntryProps {
	setIsOpen: (isOpen: boolean) => void;
}

export const TikTokDataEntry: React.FC<TikTokDataEntryProps> = () => {
	const { data: metadata } = trpc.profile.getTikTokMetaData.useQuery();
	const [openPage, setOpenPage] = useState<
		"country" | "gender" | "age" | undefined
	>(undefined);

	const content = useMemo(() => {
		if (openPage === "country") {
			return (
				<TopCountriesForm
					onClose={() => setOpenPage(() => undefined)}
					metadata={metadata}
				/>
			);
		} else if (openPage === "gender") {
			return (
				<GenderForm
					onClose={() => setOpenPage(() => undefined)}
					metadata={metadata}
				/>
			);
		} else if (openPage === "age") {
			return (
				<AgeForm
					onClose={() => setOpenPage(() => undefined)}
					metadata={metadata}
				/>
			);
		}

		return (
			<Modal.Body>
				<div className="flex w-full flex-col gap-4">
					<Button
						leadingIcon={
							metadata?.demographics?.country
								? PencilSimple
								: GlobeHemisphereWest
						}
						size="md"
						variant="secondary"
						onClick={() => setOpenPage(() => "country")}
					>
						{metadata?.demographics?.country ? "Edit" : "Add"} top countries
					</Button>
					<Button
						leadingIcon={metadata?.demographics?.age ? PencilSimple : User}
						size="md"
						variant="secondary"
						onClick={() => setOpenPage(() => "age")}
					>
						{metadata?.demographics?.age ? "Edit" : "Add"} audience age
					</Button>
					<Button
						leadingIcon={metadata?.demographics?.gender ? PencilSimple : User}
						size="md"
						variant="secondary"
						onClick={() => setOpenPage(() => "gender")}
					>
						{metadata?.demographics?.gender ? "Edit" : "Add"} audience gender
					</Button>
				</div>
			</Modal.Body>
		);
	}, [metadata, openPage]);

	const pageTitle = useMemo(() => {
		if (openPage === "country") {
			return "Add top Countries";
		} else if (openPage === "age") {
			return "Add audience age";
		} else if (openPage === "gender") {
			return "Add audience gender";
		} else {
			return "Add TikTok audience data";
		}
	}, [openPage]);

	return (
		<>
			<Modal.Header
				title={pageTitle}
				description={
					<a
						href="https://blog.hootsuite.com/tiktok-analytics/#:~:text=Visit%20the%20Followers%20tab%20to,of%20your%20followers%20by%20gender"
						className="text-body-sm text-gray-100 underline"
						target="_blank"
					>
						Learn how to find audience data from the TikTok app
					</a>
				}
			/>

			{content}
		</>
	);
};

interface TopCountriesFormProps {
	onClose: () => void;
	metadata?: TikTokMetaData;
}

const TopCountriesForm: React.FC<TopCountriesFormProps> = ({
	onClose,
	metadata,
}) => {
	const countries = Array.from(
		Object.entries(metadata?.demographics?.country ?? {}),
	).sort(([_ka, a], [_kb, b]) => b - a);
	const upsertCountryData = trpc.profile.upsertTikTokCountryData.useMutation();
	const utils = trpc.useContext();
	const form = useZodForm({
		schema: CountryDataFormSchema,
		values: {
			firstCountry: {
				country: countries[0]?.[0] ?? "",
				percentage: countries[0]?.[1].toString() ?? "",
			},
			secondCountry: {
				country: countries[1]?.[0] ?? "",
				percentage: countries[1]?.[1].toString() ?? "",
			},
			thirdCountry: {
				country: countries[2]?.[0] ?? "",
				percentage: countries[2]?.[1].toString() ?? "",
			},
			fourthCountry: {
				country: countries[3]?.[0] ?? "",
				percentage: countries[3]?.[1].toString() ?? "",
			},
			fifthCountry: {
				country: countries[4]?.[0] ?? "",
				percentage: countries[4]?.[1].toString() ?? "",
			},
		},
		submit: (values) => {
			upsertCountryData.mutate(values, {
				onSuccess: () => {
					utils.profile.getTikTokMetaData.invalidate();
					utils.mediaKit.invalidate();
					onClose();
				},
			});
		},
	});

	return (
		<>
			<Modal.Body>
				<FormProvider {...form}>
					<form>
						<div className="flex w-full flex-col gap-4">
							<div className="flex w-full items-center gap-4">
								<p className="font-repro text-button-lg">#1</p>
								<div className="w-full">
									<CountrySelect name="firstCountry.country" />
								</div>
								<div className="w-20 min-w-20">
									<ZodInput
										name="firstCountry.percentage"
										placeholder="0"
										leadingIcon={() => <div>%</div>}
									/>
								</div>
							</div>
							<div className="flex w-full items-center gap-4">
								<p className="font-repro text-button-lg">#2</p>
								<div className="w-full">
									<CountrySelect name="secondCountry.country" />
								</div>
								<div className="w-20 min-w-20">
									<ZodInput
										name="secondCountry.percentage"
										placeholder="0"
										leadingIcon={() => <div>%</div>}
									/>
								</div>
							</div>
							<div className="flex w-full items-center gap-4">
								<p className="font-repro text-button-lg">#3</p>
								<div className="w-full">
									<CountrySelect name="thirdCountry.country" />
								</div>
								<div className="w-20 min-w-20">
									<ZodInput
										name="thirdCountry.percentage"
										placeholder="0"
										leadingIcon={() => <div>%</div>}
									/>
								</div>
							</div>
							<div className="flex w-full items-center gap-4">
								<p className="font-repro text-button-lg">#4</p>
								<div className="w-full">
									<CountrySelect name="fourthCountry.country" />
								</div>
								<div className="w-20 min-w-20">
									<ZodInput
										name="fourthCountry.percentage"
										placeholder="0"
										leadingIcon={() => <div>%</div>}
									/>
								</div>
							</div>
							<div className="flex w-full items-center gap-4">
								<p className="font-repro text-button-lg">#5</p>
								<div className="w-full">
									<CountrySelect name="fifthCountry.country" />
								</div>
								<div className="w-20 min-w-20">
									<ZodInput
										name="fifthCountry.percentage"
										placeholder="0"
										leadingIcon={() => <div>%</div>}
									/>
								</div>
							</div>
						</div>
					</form>
				</FormProvider>
			</Modal.Body>
			<Modal.Footer
				primaryLabel="Save"
				onPrimaryClicked={() => {
					form.onSubmit();
				}}
				buttons="primary-secondary"
				isPrimaryDisabled={
					upsertCountryData.isLoading || !form.formState.isValid
				}
				secondaryLabel="Back"
				onSecondaryClicked={() => {
					onClose();
				}}
				layout="separated"
			/>
		</>
	);
};

interface AgeFormProps {
	onClose: () => void;
	metadata?: TikTokMetaData;
}

const AgeForm: React.FC<AgeFormProps> = ({ onClose, metadata }) => {
	const upsertAgeData = trpc.profile.upsertTikTokAgeData.useMutation();
	const utils = trpc.useContext();

	const form = useZodForm({
		schema: AgeDataFormSchema,
		values: {
			"18-24": (metadata?.demographics?.age?.["18-24"] ?? "").toString() ?? "",
			"25-34": (metadata?.demographics?.age?.["25-34"] ?? "").toString() ?? "",
			"35-44": (metadata?.demographics?.age?.["35-44"] ?? "").toString() ?? "",
			"45-54": (metadata?.demographics?.age?.["45-54"] ?? "").toString() ?? "",
			"55+": (metadata?.demographics?.age?.["55+"] ?? "").toString() ?? "",
		},
		submit: (values) => {
			upsertAgeData.mutate(values, {
				onSuccess: () => {
					utils.profile.getTikTokMetaData.invalidate();
					utils.mediaKit.invalidate();
					onClose();
				},
			});
		},
	});

	return (
		<>
			<Modal.Body>
				<FormProvider {...form}>
					<form className="flex w-full flex-col">
						<div className="flex flex-col items-center justify-center gap-4">
							<div className="flex w-full items-center justify-around gap-8">
								<p className="font-repro text-button-lg w-12 min-w-[48px] whitespace-nowrap">
									18-24 years old
								</p>
								<div className="w-24">
									<ZodInput
										name="18-24"
										placeholder="0"
										leadingIcon={() => <div>%</div>}
									/>
								</div>
							</div>
							<div className="flex w-full items-center justify-around gap-8">
								<p className="font-repro text-button-lg w-12 min-w-[48px] whitespace-nowrap">
									25-34 years old
								</p>
								<div className="w-24">
									<ZodInput
										name="25-34"
										placeholder="0"
										leadingIcon={() => <div>%</div>}
									/>
								</div>
							</div>
							<div className="flex w-full items-center justify-around gap-8">
								<p className="font-repro text-button-lg w-12 min-w-[48px] whitespace-nowrap">
									35-44 years old
								</p>
								<div className="w-24">
									<ZodInput
										name="35-44"
										placeholder="0"
										leadingIcon={() => <div>%</div>}
									/>
								</div>
							</div>
							<div className="flex w-full items-center justify-around gap-8">
								<p className="font-repro text-button-lg w-12 min-w-[48px] whitespace-nowrap">
									45-54 years old
								</p>
								<div className="w-24">
									<ZodInput
										name="45-54"
										placeholder="0"
										leadingIcon={() => <div>%</div>}
									/>
								</div>
							</div>
							<div className="flex w-full items-center justify-around gap-8">
								<p className="font-repro text-button-lg w-12 min-w-[48px] whitespace-nowrap">
									55+ years old
								</p>
								<div className="w-24">
									<ZodInput
										name="55+"
										placeholder="0"
										leadingIcon={() => <div>%</div>}
									/>
								</div>
							</div>
						</div>
					</form>
				</FormProvider>
			</Modal.Body>
			<Modal.Footer
				primaryLabel="Save"
				onPrimaryClicked={() => {
					form.onSubmit();
				}}
				buttons="primary-secondary"
				isPrimaryDisabled={upsertAgeData.isLoading}
				secondaryLabel="Back"
				onSecondaryClicked={() => {
					onClose();
				}}
				layout="separated"
			/>
		</>
	);
};

interface GenderFormProps {
	onClose: () => void;
	metadata?: TikTokMetaData;
}

export const GenderForm: React.FC<GenderFormProps> = ({
	onClose,
	metadata,
}) => {
	const upsertGenderData = trpc.profile.upsertTikTokGenderData.useMutation();
	const utils = trpc.useContext();
	const form = useZodForm({
		schema: GenderDataFormSchema,
		values: {
			male: metadata?.demographics?.gender?.male.toString() ?? "",
			female: metadata?.demographics?.gender?.female.toString() ?? "",
		},
		submit: (values) => {
			upsertGenderData.mutate(values, {
				onSuccess: () => {
					utils.profile.getTikTokMetaData.invalidate();
					utils.mediaKit.invalidate();
					onClose();
				},
			});
		},
	});

	return (
		<>
			<Modal.Body>
				<FormProvider {...form}>
					<form className="flex w-full flex-col gap-6">
						<div className="flex items-center justify-center gap-8">
							<div className="w-24">
								<ZodInput
									label="Male"
									name="male"
									placeholder="0"
									leadingIcon={() => <div>%</div>}
								/>
							</div>
							<div className="w-24">
								<ZodInput
									name="female"
									label="Female"
									placeholder="0"
									leadingIcon={() => <div>%</div>}
								/>
							</div>
						</div>
					</form>
				</FormProvider>
			</Modal.Body>
			<Modal.Footer
				primaryLabel="Save"
				onPrimaryClicked={() => {
					form.onSubmit();
				}}
				buttons="primary-secondary"
				isPrimaryDisabled={upsertGenderData.isLoading}
				secondaryLabel="Back"
				onSecondaryClicked={() => {
					onClose();
				}}
				layout="separated"
			/>
		</>
	);
};
