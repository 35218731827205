import {
	DndContext,
	DragEndEvent,
	DragOverlay,
	KeyboardSensor,
	PointerSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import {
	restrictToParentElement,
	restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import {
	MediaKit,
	MediaKitFacebookBlock,
	MediaKitInstagramBlock,
	MediaKitTikTokBlock,
	MediaKitTwitchBlock,
	MediaKitYouTubeBlock,
} from "@withjuly/fabric";
import { Label, ToggleSwitch } from "@withjuly/solisv2";
import { ActiveEditorType } from "~/pages/creator/mediakit";
import { useUpdateMediaKitBlock } from "~/utils/api/query/mediakit";
import { getBlocksOfType } from "~/utils/mediakits";
import { SortablePlatformBlocksCard } from "./PlatformBlockCard";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { trpc } from "~/components/Utility/trpc";

interface PlatformBlocksEditorProps {
	mediaKit: MediaKit;
	setActiveEditor: (editor: ActiveEditorType) => void;
	setActivePlatform: (platform: string) => void;
	isAgency: boolean;
}

export const PlatformBlocksEditor: React.FC<PlatformBlocksEditorProps> = ({
	mediaKit,
	setActiveEditor,
	setActivePlatform,
}) => {
	const isFacebookConnectionEnabled = useFeatureFlagEnabled(
		"facebook-connection",
	);
	const isDateRangeFiltersToggleEnabled = useFeatureFlagEnabled(
		"date-range-filters-toggle",
	);
	const updateBlock = useUpdateMediaKitBlock(0);
	const toggleShowDateRangeFilters =
		trpc.mediaKit.toggleShowDateRangeFilters.useMutation();
	const utils = trpc.useContext();
	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 8,
			},
		}),
		useSensor(TouchSensor, {
			activationConstraint: {
				delay: 250,
				tolerance: 8,
			},
		}),
		useSensor(KeyboardSensor),
	);

	const instagramBlocks = getBlocksOfType<MediaKitInstagramBlock>(
		mediaKit.blocks,
		"instagram",
	);
	const tiktokBlocks = getBlocksOfType<MediaKitTikTokBlock>(
		mediaKit.blocks,
		"tiktok",
	);
	const youtubeBlocks = getBlocksOfType<MediaKitYouTubeBlock>(
		mediaKit.blocks,
		"youtube",
	);
	const facebookBlocks = getBlocksOfType<MediaKitFacebookBlock>(
		mediaKit.blocks,
		"facebook",
	);
	const twitchBlocks = getBlocksOfType<MediaKitTwitchBlock>(
		mediaKit.blocks,
		"twitch",
	);

	const connectablePlatforms: (
		| "instagram"
		| "tiktok"
		| "youtube"
		| "facebook"
		| "twitch"
	)[] = [];
	if (instagramBlocks.blocks.length === 0) {
		connectablePlatforms.push("instagram");
	}
	if (tiktokBlocks.blocks.length === 0) {
		connectablePlatforms.push("tiktok");
	}
	if (youtubeBlocks.blocks.length === 0) {
		connectablePlatforms.push("youtube");
	}
	if (facebookBlocks.blocks.length === 0 && isFacebookConnectionEnabled) {
		connectablePlatforms.push("facebook");
	}
	if (twitchBlocks.blocks.length === 0) {
		connectablePlatforms.push("twitch");
	}

	const platformBlocks = [
		instagramBlocks,
		tiktokBlocks,
		youtubeBlocks,
		facebookBlocks,
		twitchBlocks,
	].filter((b) => b.blocks.length > 0);
	platformBlocks.sort((a, b) => a.lowestOrder - b.lowestOrder);

	const onDragEnd = async (event: DragEndEvent) => {
		const { active, over } = event;

		const overBlock = platformBlocks.findIndex((p) => p.type === over?.id);

		// Move the block within the local array
		//
		// Note that we also do this update optimistically in react-query's
		// onMutate, however that only comes in two ticks after the drag end event,
		// which causes things to jump around.
		const oldIndex = platformBlocks.findIndex((p) => p.type === active.id);
		const activeBlock = platformBlocks.splice(oldIndex, 1)[0];
		if (activeBlock) {
			platformBlocks.splice(overBlock, 0, activeBlock);
			platformBlocks.forEach((b, i) => (b.lowestOrder = i));
			platformBlocks.sort((a, b) => a.lowestOrder - b.lowestOrder);
		}

		updateBlock.mutate({
			uuid: platformBlocks[overBlock]?.blocks[0]?.uuid ?? "",
			data: {
				type: activeBlock?.type ?? "",
				order: overBlock ?? 0,
			},
		});
	};

	if (platformBlocks.length === 0) {
		return null;
	}

	return (
		<div className="flex flex-col gap-2">
			<Label size="xs" color="secondary" variant="overline">
				Socials
			</Label>

			{isDateRangeFiltersToggleEnabled ? (
				<div className="mb-2 mt-1 flex flex-row items-center gap-4">
					<ToggleSwitch
						toggle={mediaKit.showDateRangeFilters}
						onToggle={(toggle) => {
							if (toggle !== undefined) {
								toggleShowDateRangeFilters.mutate(
									{ uuid: mediaKit.uuid, toggle: toggle },
									{
										onSuccess: () => {
											utils.mediaKit.invalidate();
										},
									},
								);
							}
						}}
					/>
					<div className="flex items-center gap-2">
						<Label size="sm" variant="paragraph" color="secondary">
							Show date range filters
						</Label>
					</div>
				</div>
			) : null}

			<div className="flex w-full flex-col gap-2">
				<DndContext
					onDragEnd={onDragEnd}
					sensors={sensors}
					modifiers={[restrictToVerticalAxis, restrictToParentElement]}
				>
					<SortableContext
						items={platformBlocks.map((b) => b.type)}
						strategy={rectSortingStrategy}
					>
						{platformBlocks.map((blocks, index) => (
							<SortablePlatformBlocksCard
								key={index}
								blocks={blocks.blocks}
								platform={blocks.type}
								setActiveEditor={setActiveEditor}
								setActivePlatform={setActivePlatform}
							/>
						))}
					</SortableContext>

					<DragOverlay dropAnimation={null} />
				</DndContext>
			</div>
		</div>
	);
};
