import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { MediaKitPlatformBlock } from "@withjuly/fabric";
import { forwardRef } from "react";
import { PlatformData } from "~/components/Mediakits/store";
import { useUpdateMediaKitBlock } from "~/utils/api/query/mediakit";
import { DotsSixVertical, CaretRight } from "@withjuly/julycons/bold";
import { ActiveEditorType } from "../MediaKitEditor";
import { ToggleSwitch } from "@withjuly/solisv2";

export const SortablePlatformBlocksCard = ({
	blocks,
	platform,
	setActiveEditor,
	setActivePlatform,
}: {
	blocks: MediaKitPlatformBlock[];
	platform: string;
	setActiveEditor: (editor: ActiveEditorType) => void;
	setActivePlatform: (platform: string) => void;
}) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({ id: platform });

	return (
		<PlatformBlockCard
			blocks={blocks}
			ref={setNodeRef}
			style={{
				transform: CSS.Translate.toString(transform),
				transition,
				opacity: isDragging ? 0.75 : 1,
			}}
			{...attributes}
			{...listeners}
			setActiveEditor={setActiveEditor}
			setActivePlatform={setActivePlatform}
		/>
	);
};

export const PlatformBlockCard = forwardRef<
	HTMLDivElement,
	React.HtmlHTMLAttributes<HTMLDivElement> & {
		blocks: MediaKitPlatformBlock[];
		setActiveEditor: (editor: ActiveEditorType) => void;
		setActivePlatform: (platform: string) => void;
	}
>(({ blocks, setActiveEditor, setActivePlatform, ...props }, ref) => {
	const updateBlock = useUpdateMediaKitBlock();

	if (blocks && blocks[0]) {
		const platformData = PlatformData[blocks[0].type];

		return (
			<div
				className="bg-surface-hover-1 hover:bg-surface-hover-2 flex h-12 cursor-pointer items-center justify-between rounded-lg p-4 ease-in-out"
				role="button"
				{...props}
				ref={ref}
				aria-label={`Edit ${platformData.name} block`}
				onClick={() => {
					if (blocks && blocks[0]) {
						setActivePlatform(blocks[0].type);
					}

					setActiveEditor("platform");
				}}
			>
				<div className="flex items-center gap-4">
					<DotsSixVertical />
					<platformData.icon className="h-6 w-6" />
					<div className="flex flex-col">
						<p className="text-button-xs font-repro">{platformData.name}</p>
						<p className="text-paragraph-xs text-text-secondary font-repro">
							{blocks.length === 1
								? blocks[0].fields.username
								: `${blocks.length} accounts`}
						</p>
					</div>
				</div>

				<div className="flex items-center gap-4">
					{blocks.length === 1 ? (
						<div className="flex" onClick={(e) => e.stopPropagation()}>
							<ToggleSwitch
								toggle={blocks[0].enabled}
								onToggle={() => {
									if (blocks[0]) {
										updateBlock.mutate({
											uuid: blocks[0].uuid,
											data: {
												type: blocks[0].type,
												enabled: !blocks[0].enabled,
											},
										});
									}
								}}
							/>
						</div>
					) : null}
					<CaretRight className="text-text-tertiary" />
				</div>
			</div>
		);
	} else {
		return null;
	}
});
PlatformBlockCard.displayName = "PlatformBlockCard";
