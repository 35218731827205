import {
	DndContext,
	DragEndEvent,
	KeyboardSensor,
	PointerSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import {
	restrictToParentElement,
	restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useQuery } from "@tanstack/react-query";
import {
	MediaKitCollab,
	MediaKitCollabStat,
	MediaKitCollabsBlock,
	UpsertMediaKitCollabSchema,
	UpsertMediaKitCollabStatSchema,
} from "@withjuly/fabric";

import { Loader, useToast } from "@withjuly/solis";
import {
	Trash,
	DotsSixVertical,
	UploadSimple,
	CaretRight,
	ArrowLeft,
	Plus,
} from "@withjuly/julycons/bold";
import { forwardRef, useMemo, useRef, useState } from "react";
import { FormProvider } from "react-hook-form";

import { trpc } from "~/components/Utility/trpc";
import { ActiveEditorType } from "~/pages/creator/mediakit";
import { useUpdateMediaKitCollabOrder } from "~/utils/api/query/mediakit";
import { useZodForm } from "~/utils/hooks/zod-form";
import {
	IconButton,
	Button,
	Label,
	Modal,
	Input,
	ScrollArea,
} from "@withjuly/solisv2";
import { ZodInput } from "~/components/Input/ZodInput";
import { ZodTextArea } from "~/components/Input/ZodTextArea";

interface CollabsEditor {
	block: MediaKitCollabsBlock;
	onClickBack: (editor: ActiveEditorType) => void;
}

export const CollabsEditor: React.FC<CollabsEditor> = ({
	block,
	onClickBack,
}) => {
	const [isUpserting, setIsUpserting] = useState(false);
	const [editingCollabUuid, setEditingCollabUuid] = useState<string>();
	const updateOrder = useUpdateMediaKitCollabOrder(0);

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 8,
			},
		}),
		useSensor(TouchSensor, {
			activationConstraint: {
				delay: 250,
				tolerance: 8,
			},
		}),
		useSensor(KeyboardSensor),
	);

	const onDragEnd = async (event: DragEndEvent) => {
		const { active, over } = event;

		const overBlock = block?.collabs.findIndex((b) => b.uuid === over?.id);

		// Move the block within the local array
		//
		// Note that we also do this update optimistically in react-query's
		// onMutate, however that only comes in two ticks after the drag end event,
		// which causes things to jump around.
		const oldIndex = block.collabs.findIndex((b) => b.uuid === active.id);
		const activeBlock = block.collabs.splice(oldIndex, 1)[0];
		if (activeBlock) {
			block.collabs.splice(overBlock, 0, activeBlock);
			block.collabs.forEach((b, i) => (b.order = i));
			block.collabs.sort((a, b) => a.order - b.order);
		}

		updateOrder.mutate({ uuid: active.id as string, order: overBlock ?? 0 });
	};

	if (isUpserting) {
		return (
			<UpsertCollabForm
				collab={block.collabs.find(
					(collab) => collab.uuid === editingCollabUuid,
				)}
				isOpen={isUpserting}
				setIsOpen={(isOpen) => {
					if (!isOpen) {
						setEditingCollabUuid(undefined);
					}

					setIsUpserting(isOpen);
				}}
				isNew={editingCollabUuid ? false : true}
			/>
		);
	}

	return (
		<div className="flex w-full flex-col rounded-lg">
			<div className="flex w-full items-center gap-4 px-8 py-7">
				<IconButton
					icon={ArrowLeft}
					size="sm"
					variant="secondary"
					onClick={() => onClickBack("none")}
				/>
				<p className="text-header-xl font-repro">Previous Collaborations</p>
			</div>
			<div className="bg-stroke-secondary h-px w-full" />
			<ScrollArea className="max-h-[calc(100vh_-_152px)]">
				<div className="flex  flex-col gap-2 p-8">
					<DndContext
						onDragEnd={onDragEnd}
						sensors={sensors}
						modifiers={[restrictToVerticalAxis, restrictToParentElement]}
					>
						<SortableContext
							items={block.collabs
								.sort((a, b) => a.order - b.order)
								.map((collab) => collab.uuid)}
						>
							{block.collabs.map((collab) => (
								<SortableCollab
									collab={collab}
									key={collab.uuid}
									setEditingCollabUuid={setEditingCollabUuid}
									setIsUpserting={setIsUpserting}
								/>
							))}
						</SortableContext>
					</DndContext>

					<Button
						leadingIcon={Plus}
						size="md"
						variant="blank"
						onClick={() => setIsUpserting(true)}
						className="min-h-10"
					>
						Add Previous Collaboration
					</Button>
				</div>
			</ScrollArea>
		</div>
	);
};

interface CollabProps {
	collab: MediaKitCollab;
	setIsUpserting: (isUpserting: boolean) => void;
	setEditingCollabUuid: (collabUuid: string) => void;
}

const SortableCollab: React.FC<CollabProps> = ({
	collab,
	setIsUpserting,
	setEditingCollabUuid,
}) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({ id: collab.uuid });

	return (
		<div
			ref={setNodeRef}
			{...listeners}
			{...attributes}
			style={{
				transform: CSS.Translate.toString(transform),
				transition,
				opacity: isDragging ? 0.75 : 1,
			}}
		>
			<Collab
				collab={collab}
				setIsUpserting={setIsUpserting}
				setEditingCollabUuid={setEditingCollabUuid}
			/>
		</div>
	);
};

const Collab = forwardRef<
	HTMLButtonElement,
	React.ButtonHTMLAttributes<HTMLButtonElement> & CollabProps
>(({ collab, setIsUpserting, setEditingCollabUuid, ...props }, ref) => {
	return (
		<button
			key={collab.uuid}
			className="bg-surface-hover-1 hover:bg-surface-hover-2 flex h-12 w-full items-center justify-between gap-4 rounded-lg p-4 transition-all "
			onClick={() => {
				setIsUpserting(true);
				setEditingCollabUuid(collab.uuid);
			}}
			ref={ref}
			{...props}
		>
			<div className="flex items-center gap-4">
				<DotsSixVertical />
				<p className="font-repro text-sm">{collab.title}</p>
			</div>
			<div className="flex items-center gap-2">
				<CaretRight className="text-text-tertiary" />
			</div>
		</button>
	);
});
Collab.displayName = "Collab";

interface UpsertCollabFormProps {
	collab?: MediaKitCollab;
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	isNew: boolean;
}

const UpsertCollabForm: React.FC<UpsertCollabFormProps> = ({
	collab,
	setIsOpen,
	isNew,
}) => {
	const [isStatEditorOpen, setIsStatEditorOpen] = useState(false);
	const [editingStat, setEditingStat] = useState<number>();

	const utils = trpc.useContext();
	const [stats, setStats] = useState<MediaKitCollabStat[]>(
		() => collab?.stats ?? [],
	);

	const deleteCollab = trpc.mediaKit.deleteCollab.useMutation({
		onSuccess: () => utils.mediaKit.get.invalidate(),
	});

	const getUploadUrl = trpc.mediaKit.upload.useMutation();
	const upsertCollab = trpc.mediaKit.upsertCollab.useMutation({
		onSuccess: () => {
			utils.mediaKit.get.invalidate();
			setIsOpen(false);
			setLocalImage(undefined);
		},
	});
	const [localImage, setLocalImage] = useState<string>();

	const uploadRef = useRef<HTMLInputElement | null>(null);
	const [isUploading, setIsUploading] = useState(false);
	const { toast } = useToast();

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 8,
			},
		}),
		useSensor(TouchSensor, {
			activationConstraint: {
				delay: 250,
				tolerance: 8,
			},
		}),
		useSensor(KeyboardSensor),
	);

	const onDragStatEnd = async (event: DragEndEvent) => {
		const { active, over } = event;
		const newStats = [...stats];
		const newIndex = newStats.findIndex((b) => b.title === over?.id);

		// Move the block within the local array
		//
		// Note that we also do this update optimistically in react-query's
		// onMutate, however that only comes in two ticks after the drag end event,
		// which causes things to jump around.
		const oldIndex = newStats.findIndex((b) => b.title === active.id);
		const activeBlock = newStats.splice(oldIndex, 1)[0];

		if (activeBlock) {
			newStats.splice(newIndex, 0, activeBlock);
			setStats(() => newStats);
		}
	};

	const form = useZodForm({
		schema: UpsertMediaKitCollabSchema,
		defaultValues: {
			uuid: collab?.uuid,
			title: collab?.title ?? "",
			description: collab?.description ?? "",
			logoUuid: collab?.logoUuid,
			url: collab?.url?.replace("https://", "").replace("http://", ""),
			testimonial: collab?.testimonial,
			stats: collab?.stats ?? [],
		},
		submit: async (data) => {
			upsertCollab.mutate(
				{
					...data,
					stats: stats,
				},
				{
					onSuccess: () => form.reset(),
				},
			);
		},
	});

	const onUpload = async (file: File) => {
		setIsUploading(true);

		if (file) {
			if (file.size > 2_000_000) {
				toast({
					title: "That file is too large",
					description: "Please upload a file less than 2MB",
					status: "danger",
				});
				setIsUploading(false);
				return;
			}

			const { url, fields, upload } = await getUploadUrl.mutateAsync({
				fileName: file.name,
				fileType: file.type,
				size: file.size,
			});

			const formData = new FormData();
			Object.entries(fields).forEach(([k, v]) => formData.append(k, v));
			formData.append("Content-Type", file.type);
			formData.append("file", file);
			await fetch(url, {
				method: "POST",
				body: formData,
			});

			setLocalImage(URL.createObjectURL(file));
			form.setValue("logoUuid", upload.uuid);
		}

		setIsUploading(false);
	};

	const getUploadContent = () => {
		if (localImage) {
			return null;
		}

		if (isUploading) {
			return <Loader />;
		} else if (!collab?.logoUrl) {
			return (
				<>
					<UploadSimple />
				</>
			);
		} else {
			return null;
		}
	};

	const [_, setSelectedSuggestion] = useState<{
		name: string;
		domain: string;
		logo: string;
	}>();
	const { data: suggestions } = useQuery(
		["mediaKit", "collab", "clearbit", form.watch("title")],
		async () => {
			const query = form.watch("title");
			const results = await (
				await fetch(
					`https://autocomplete.clearbit.com/v1/companies/suggest?query=${query}`,
				)
			).json();
			return results as { name: string; domain: string; logo: string }[];
		},
		{
			enabled: form.watch("title") !== "",
		},
	);

	const isFormValid = useMemo(() => {
		return (
			form.getValues().url &&
			form.getValues().url !== "" &&
			form.getValues().title &&
			form.getValues().title !== "" &&
			form.getValues().logoUuid &&
			form.getValues().logoUuid !== ""
		);
	}, [form]);

	return (
		<>
			<div className="flex h-full w-full flex-col rounded-lg">
				<div className="flex w-full items-center gap-4 px-8 py-7">
					<IconButton
						icon={ArrowLeft}
						size="sm"
						variant="secondary"
						onClick={() => setIsOpen(false)}
					/>
					<p className="text-header-xl font-repro">
						{isNew ? "New" : "Edit"} Collaboration
					</p>
				</div>
				<div className="bg-stroke-secondary h-px w-full" />

				<ScrollArea className="max-h-[calc(100vh_-_280px)]">
					<FormProvider {...form}>
						<form className=" flex  w-full flex-col gap-6 px-8 pb-6 pt-8">
							<div className="flex flex-col gap-6">
								<ZodInput
									name="url"
									label="Link to Post"
									placeholder="Add Link"
									leadingIcon={() => <p>https://</p>}
									onChange={(e) => {
										const url = e.target.value;
										if (url.startsWith("https://")) {
											form.setValue("url", url.replace("https://", ""));
										} else if (url.startsWith("http://")) {
											form.setValue("url", url.replace("http://", ""));
										} else {
											form.setValue("url", url);
										}
									}}
								/>

								<div className="flex gap-4">
									<div className="flex flex-col gap-2">
										<Label variant="overline" size="xs" color="secondary">
											Logo
										</Label>
										<div
											onClick={() => uploadRef.current?.click()}
											className="bg-surface-hover-1 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full"
											style={{
												backgroundImage:
													collab?.logoUrl ?? localImage
														? `url(${localImage ?? collab?.logoUrl})`
														: undefined,
												backgroundSize: "cover",
												backgroundPosition: "center",
												backgroundRepeat: "no-repeat",
											}}
										>
											{getUploadContent()}
											<input
												className="hidden"
												type="file"
												accept="image/*"
												ref={uploadRef}
												onChange={async (e) => {
													const file = e.target.files?.[0];
													if (file) {
														await onUpload(file);
													}
												}}
											/>
										</div>
									</div>

									<Input
										label="Brand Name"
										variant="dropdown"
										placeholder="Add brand name"
										value={form.watch("title")}
										onChange={(e) => form.setValue("title", e.target.value)}
										onOptionSelected={async (value) => {
											const suggestion = suggestions?.find(
												(s) => s.name === value,
											);
											if (!suggestion) {
												return;
											}

											form.setValue("title", suggestion.name);

											const file = await (await fetch(suggestion.logo)).blob();
											await onUpload(
												new File([file], suggestion.name + ".png"),
											);
											setSelectedSuggestion(suggestion);
										}}
										options={suggestions?.map((suggestion) => {
											return {
												display: suggestion.name,
												value: suggestion.name,
												icon: () => (
													<img
														src={suggestion.logo}
														alt={suggestion.name}
														className="aspect-square h-4 min-h-4 w-4 min-w-4 rounded-full"
													/>
												),
											};
										})}
									/>
								</div>

								<ZodTextArea
									name="description"
									label="Description (optional)"
									placeholder="Describe your parternship and what was delivered."
								/>

								<ZodTextArea
									name="testimonial"
									label="Testimonial (optional)"
									placeholder="Add a quote from the brand."
								/>

								<div className="bg-stroke-tertiary h-px w-full" />
							</div>
						</form>
					</FormProvider>

					<div className="flex w-full flex-col gap-2 px-8 pb-8">
						<Label variant="overline" size="xs" color="secondary">
							Stats
						</Label>
						<div className="flex w-full flex-col gap-2">
							<DndContext
								onDragEnd={onDragStatEnd}
								sensors={sensors}
								modifiers={[restrictToVerticalAxis, restrictToParentElement]}
							>
								<SortableContext items={stats.map((stat) => stat.title)}>
									{stats.map((stat, index) => (
										<SortableStat
											key={stat.title}
											stat={stat}
											setStats={setStats}
											index={index}
											setEditingStat={setEditingStat}
											setIsStatEditorOpen={setIsStatEditorOpen}
										/>
									))}
								</SortableContext>
							</DndContext>
						</div>
						<Button
							leadingIcon={Plus}
							size="md"
							variant="blank"
							onClick={(e) => {
								e.preventDefault();
								setIsStatEditorOpen(() => true);
							}}
						>
							Add Stat
						</Button>
					</div>
				</ScrollArea>
			</div>

			<div className="border-stroke-secondary bg-surface-secondary absolute bottom-0 flex min-w-[480px] max-w-[480px] overflow-hidden border-t px-8 py-7">
				{isNew ? (
					<div className="relative flex w-full flex-col">
						<Button
							size="md"
							onClick={(e) => {
								e.preventDefault();
								form.onSubmit();
							}}
							disabled={!isFormValid}
						>
							Save
						</Button>
					</div>
				) : (
					<div className="relative flex w-full flex-col ">
						<div className="flex w-full gap-4">
							<Button
								size="md"
								variant="outline"
								onClick={(e) => {
									e.preventDefault();
									if (collab) {
										deleteCollab.mutate(collab.uuid);
										setIsOpen(false);
									}
								}}
								className="w-1/2"
								leadingIcon={Trash}
							>
								Remove
							</Button>
							<Button
								size="md"
								onClick={(e) => {
									e.preventDefault();
									form.onSubmit();
								}}
								className="w-1/2"
								disabled={!isFormValid}
							>
								Save Changes
							</Button>
						</div>
					</div>
				)}
			</div>

			<div className="z-[2000]">
				<CollabStatEditor
					isOpen={isStatEditorOpen}
					setIsOpen={(isOpen) => {
						if (!isOpen) {
							setEditingStat(undefined);
						}
						setIsStatEditorOpen(isOpen);
					}}
					setStats={setStats}
					stat={editingStat !== undefined ? stats[editingStat] : undefined}
					statIndex={editingStat}
				/>
			</div>
		</>
	);
};

interface CollabStatEditorProps {
	setStats: React.Dispatch<React.SetStateAction<MediaKitCollabStat[]>>;
	stat?: MediaKitCollabStat;
	statIndex?: number;
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CollabStatEditor: React.FC<CollabStatEditorProps> = ({
	setStats,
	stat,
	statIndex,
	isOpen,
	setIsOpen,
}) => {
	const form = useZodForm({
		schema: UpsertMediaKitCollabStatSchema,
		values: {
			title: stat?.title ?? "",
			value: stat?.value ?? "",
			statIndex: statIndex,
		},
		submit: async (data) => {
			if (statIndex !== undefined) {
				setStats((prev) => {
					prev[statIndex] = data;
					return [...prev];
				});
			} else {
				setStats((prev) => [...prev, data]);
			}

			form.reset();
			setIsOpen(false);
		},
	});

	return (
		<Modal.Root isOpen={isOpen} setIsOpen={setIsOpen}>
			<Modal.Header title={stat ? "Update Stat" : "Add Stat"} />

			<Modal.Body>
				<FormProvider {...form}>
					<form className="flex flex-col gap-6">
						<div className="flex flex-col gap-3">
							<ZodInput
								name="title"
								label="Stat Title"
								placeholder="Add stat title (for example Link Clicks)"
							/>
							<ZodInput
								name="value"
								label="Value"
								placeholder="Add value (for example 500)"
							/>
						</div>
					</form>
				</FormProvider>
			</Modal.Body>
			<Modal.Footer
				primaryLabel="Save"
				onPrimaryClicked={() => {
					form.onSubmit();
				}}
				buttons="primary"
				isPrimaryDisabled={false}
			/>
		</Modal.Root>
	);
};

interface SortableStatProps {
	index: number;
	stat: MediaKitCollabStat;
	setIsStatEditorOpen: (isOpen: boolean) => void;
	setStats: React.Dispatch<React.SetStateAction<MediaKitCollabStat[]>>;
	setEditingStat: (index: number) => void;
}

const SortableStat: React.FC<SortableStatProps> = ({
	index,
	stat,
	setIsStatEditorOpen,
	setStats,
	setEditingStat,
}) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({ id: stat.title });

	return (
		<div
			ref={setNodeRef}
			{...listeners}
			{...attributes}
			style={{
				transform: CSS.Translate.toString(transform),
				transition,
				opacity: isDragging ? 0.75 : 1,
			}}
		>
			<Stat
				stat={stat}
				index={index}
				setEditingStat={setEditingStat}
				setIsStatEditorOpen={setIsStatEditorOpen}
				setStats={setStats}
			/>
		</div>
	);
};

const Stat = forwardRef<
	HTMLButtonElement,
	React.ButtonHTMLAttributes<HTMLButtonElement> & SortableStatProps
>(
	(
		{ stat, setIsStatEditorOpen, setEditingStat, setStats, index, ...props },
		ref,
	) => {
		return (
			<button
				aria-label="Edit Stat"
				onClick={(e) => {
					e.preventDefault();
					setEditingStat(index);
					setIsStatEditorOpen(true);
				}}
				{...props}
				ref={ref}
				className="bg-surface-hover-1 hover:bg-surface-hover-2 flex h-12 w-full flex-row items-center justify-between gap-4 rounded-lg p-4 transition-all"
			>
				<div className="flex items-center gap-4">
					<DotsSixVertical />

					<p className="text-paragraph-sm font-repro">{stat.title}</p>
				</div>
				<div className="flex items-center gap-4">
					<p className="text-paragraph-sm font-repro">{stat.value}</p>
					<IconButton
						icon={Trash}
						variant="secondary"
						size="sm"
						onClick={(e) => {
							e.stopPropagation();
							setStats((prev: MediaKitCollabStat[]) => {
								prev.splice(index, 1);
								return [...prev];
							});
						}}
					/>
				</div>
			</button>
		);
	},
);
Stat.displayName = "Stat";
