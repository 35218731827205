import React, { useId } from "react";
import { useFormWrapper, FormInputWrapper } from "@withjuly/solis";
import { BaseInputProps, TextArea } from "@withjuly/solisv2";

export interface ZodTextAreaProps extends BaseInputProps {
	name?: string;
	rows?: number;
	label?: string;
	description?: string;
	tooltip?: string;
	setValueAs?: (value: string) => unknown;
}

export const ZodTextArea: React.FC<ZodTextAreaProps> = ({
	name,
	label,
	description,
	tooltip,
	onChange,
	onBlur,
	setValueAs,
	rows,
	...rest
}) => {
	const id = useId();
	const { register, error, hasError } = useFormWrapper(name ?? "");
	const form = register(name ?? "", {
		onChange,
		onBlur,
		setValueAs,
	});

	const describedByIds: string[] = [];
	if (error?.message) {
		describedByIds.push(id + "-error");
	}

	return (
		<FormInputWrapper id={id} name={name}>
			<TextArea
				label={label}
				tooltip={tooltip}
				description={description}
				error={hasError}
				aria-describedby={describedByIds.join(" ")}
				{...rest}
				{...form}
				rows={rows}
			/>
		</FormInputWrapper>
	);
};
