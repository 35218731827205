import { CreatorProfileFlagsSchema, CustomProfile, MediaKit, MediaKitThemeSchema } from "@withjuly/fabric";
import { cx } from "@withjuly/frontend-common";
import { Loader } from "@withjuly/solis";
import { useEffect, useMemo, useState } from "react";
import { AboutEditor } from "~/components/Mediakits/editor/AboutEditor";
import { CastingTagsEditor } from "~/components/Mediakits/editor/CastingTagsEditor";
import { CollabsEditor } from "~/components/Mediakits/editor/CollabsEditor";
import { HeaderEditor } from "~/components/Mediakits/editor/HeaderEditor";
import { InfoBlocksEditor } from "~/components/Mediakits/editor/InfoBlocksEditor";
import { PlatformBlockEditor } from "~/components/Mediakits/editor/PlatformBlockEditor";
import { PlatformBlocksEditor } from "~/components/Mediakits/editor/PlatformBlocksEditor";
import { RatesEditor } from "~/components/Mediakits/editor/RatesEditor";
import { getThemeColorClassName } from "~/components/Mediakits/utils";
import { RouterOutput, trpc } from "~/components/Utility/trpc";

import { useUpdateMediaKit } from "~/utils/api/query/mediakit";
import { useAgency } from "~/utils/context/agency";
import { useCreator } from "~/utils/context/creator";
import { getBioDomain } from "~/utils/urls";
import {
	Button,
	IconButton,
	Label,
	DropdownMenu,
	ToggleSwitch,
	ScrollArea,
} from "@withjuly/solisv2";
import {
	CopySimple,
	ArrowSquareOut,
	Check,
	CaretDown,
	Plugs,
} from "@withjuly/julycons/bold";
import { useRouter } from "next/router";
import { useAuth } from "~/utils/context/auth";

export type ActiveEditorType =
	| "none"
	| "header"
	| "casting-tags"
	| "about"
	| "platform"
	| "collabs"
	| "rates";

const supportedPlatforms = ['facebook', 'instagram', 'twitch', 'tiktok', 'youtube'];

interface MediaKitEditorProps {
	mediaKitP?: RouterOutput["mediaKit"]["get"];
}

export const MediaKitEditor: React.FC<MediaKitEditorProps> = ({
	mediaKitP,
}) => {
	const router = useRouter();

	const { data: mediaKitRes } = trpc.mediaKit.get.useQuery(undefined, {
		enabled: mediaKitP === undefined,
	});

	const mediaKit = useMemo(() => {
		return mediaKitP ?? mediaKitRes;
	}, [mediaKitP, mediaKitRes]);

	const { creatorProfile } = useCreator();
	const [activeCreatorProfileUuid, setActiveCreatorProfileUuid] = useState(
		() => creatorProfile?.uuid,
	);
	const { agencyProfile } = useAgency();
	const toggleMediaKitLocked = trpc.mediaKit.toggleLocked.useMutation();
	const utils = trpc.useContext();
	const [isPrivateLinkCopied, setIsPrivateLinkCopied] = useState(false);
	const [activeEditor, setActiveEditor] = useState<ActiveEditorType>("none");
	const [activePlatform, setActivePlatform] = useState("");
	const [hasCopied, setHasCopied] = useState(false);
	const copyTextToClipboard = (text: string) => {
		navigator.clipboard.writeText(text).then(() => {
			setHasCopied(true);
			setTimeout(() => {
				setHasCopied(false);
			}, 1000);
		});
	};
	const url = `${getBioDomain()}/${creatorProfile?.username}`;

	const updateMediaKit = useUpdateMediaKit();

	useEffect(() => {
		if (creatorProfile && creatorProfile.uuid !== activeCreatorProfileUuid) {
			setActiveEditor(() => "none");
			setActiveCreatorProfileUuid(() => creatorProfile.uuid);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [creatorProfile]);

	if (!mediaKit) {
		return (
			<div className="absolute m-auto flex h-full w-full items-center justify-center">
				<Loader />
			</div>
		);
	}

	if (activeEditor === "header") {
		return (
			<div className="flex w-full flex-col gap-4">
				<HeaderEditor onClickBack={setActiveEditor} />
			</div>
		);
	} else if (activeEditor === "casting-tags") {
		return (
			<div className="flex w-full flex-col gap-4">
				<CastingTagsEditor onClickBack={setActiveEditor} />
			</div>
		);
	} else if (activeEditor === "about") {
		return (
			<div className="flex w-full flex-col gap-4">
				<AboutEditor onClickBack={setActiveEditor} />
			</div>
		);
	} else if (activeEditor === "collabs") {
		const collabsBlock = mediaKit?.blocks.find(
			(block) => block.type === "collabs",
		);
		if (collabsBlock?.type === "collabs") {
			return (
				<div className="flex h-full w-full flex-col gap-4">
					<CollabsEditor block={collabsBlock} onClickBack={setActiveEditor} />
				</div>
			);
		}
		return null;
	} else if (activeEditor === "rates") {
		const ratesBlock = mediaKit?.blocks.find((block) => block.type === "rates");

		if (ratesBlock?.type === "rates") {
			return (
				<div className="flex w-full flex-col gap-4">
					<RatesEditor
						block={ratesBlock}
						onClickBack={setActiveEditor}
						currency={mediaKit.currency}
					/>
				</div>
			);
		}
		return null;
	} else if (activeEditor == "platform") {
		return (
			<div className="flex w-full flex-col gap-4">
				<PlatformBlockEditor
					mediaKit={mediaKit}
					platform={activePlatform}
					onClickBack={setActiveEditor}
				/>
			</div>
		);
	} else {
		return (
			<div className="flex w-full h-full flex-col">
				<div className="flex w-full flex-col gap-6 px-8 py-7">
					{agencyProfile && (
						<div className="flex w-full items-center justify-between">
							<p className="text-header-xl font-repro max-w-[200px] truncate">
								{mediaKit.name}
							</p>
							<div className="flex gap-4">
								<IconButton
									size="sm"
									icon={hasCopied ? Check : CopySimple}
									variant="blank-primary"
									onClick={() => copyTextToClipboard(`https://${url}`)}
								/>
								<Button
									size="sm"
									trailingIcon={ArrowSquareOut}
									onClick={() => window.open("https://" + url, "_blank")}
								>
									View Media Kit
								</Button>
							</div>
						</div>
					)}
					{!agencyProfile ? (
						<div className="flex w-full items-center justify-between gap-4">
							<div className="flex w-full flex-1 flex-col gap-2">
								<Label size="xs" variant="overline" color="secondary">
									Mode
								</Label>
								<DropdownMenu.Root>
									<DropdownMenu.Trigger variant="custom">
										<div className="bg-surface-hover-1 hover:bg-surface-hover-2 flex h-10 cursor-pointer items-center justify-between rounded-lg px-3">
											<div className="flex items-center gap-2">
												<span
													className={cx(
														"bg-white-100 h-4 w-4 rounded-full",
														mediaKit.darkMode &&
															"bg-surface-primary border-stroke-primary border",
													)}
												/>
												<p className="text-paragraph-md font-repro">
													{mediaKit.darkMode ? "Dark" : "Light"}
												</p>
											</div>
											<CaretDown />
										</div>
									</DropdownMenu.Trigger>
									<DropdownMenu.Content className="bg-surface-primary rounded-solis-lg border-stroke-primary divide-stroke-tertiary z-[2000] translate-y-2 transform divide-y-[0.5px] overflow-clip border">
										<DropdownMenu.Item
											className="cursor-pointer"
											onSelect={() => {
												updateMediaKit.mutate({
													...mediaKit,
													darkMode: true,
												});
											}}
										>
											<div className="flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg px-0.5 py-0.5">
												<span className="bg-surface-primary border-stroke-primary h-4 w-4 rounded-full border" />
												<p className="text-paragraph-md font-repro">Dark</p>
											</div>
										</DropdownMenu.Item>
										<DropdownMenu.Item
											className="cursor-pointer"
											onSelect={() => {
												updateMediaKit.mutate({
													...mediaKit,
													darkMode: false,
												});
											}}
										>
											<div className="flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg px-0.5 py-0.5">
												<span className="bg-white-100 h-4 w-4 rounded-full" />
												<p className="text-paragraph-md font-repro">Light</p>
											</div>
										</DropdownMenu.Item>
									</DropdownMenu.Content>
								</DropdownMenu.Root>
							</div>
							<div className="flex flex-1 flex-col gap-2">
								<Label size="xs" variant="overline" color="secondary">
									Theme
								</Label>
								<DropdownMenu.Root>
									<DropdownMenu.Trigger variant="custom">
										<div className="bg-surface-hover-1 hover:bg-surface-hover-2 flex h-10 cursor-pointer items-center justify-between rounded-lg px-3 ">
											<div className="flex items-center gap-2">
												<span
													className={cx(
														"h-4 w-4 rounded-full",
														getThemeColorClassName(mediaKit.theme),
													)}
												/>
												<p className="text-paragraph-md font-repro">
													{mediaKit.theme.charAt(0).toUpperCase() +
														mediaKit.theme.slice(1)}
												</p>
											</div>
											<CaretDown />
										</div>
									</DropdownMenu.Trigger>
									<DropdownMenu.Content className="bg-surface-primary rounded-solis-lg border-stroke-primary divide-stroke-tertiary z-[5000] translate-y-2 transform divide-y-[0.5px] overflow-clip border">
										{MediaKitThemeSchema.options.map((theme, i) => {
											return (
												<DropdownMenu.Item
													key={`${theme}-${i}`}
													className="cursor-pointer"
													onSelect={() => {
														updateMediaKit.mutate({
															...mediaKit,
															theme: theme,
														});
													}}
												>
													<div className="flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg px-0.5 py-0.5">
														<span
															className={cx(
																"h-4 w-4 rounded-full",
																getThemeColorClassName(theme),
																theme === "black" &&
																	"border-stroke-primary border",
															)}
														/>
														<p className="text-paragraph-md font-repro">
															{theme.charAt(0).toUpperCase() + theme.slice(1)}
														</p>
													</div>
												</DropdownMenu.Item>
											);
										})}
									</DropdownMenu.Content>
								</DropdownMenu.Root>
							</div>
						</div>
					) : null}
				</div>

				<div className="bg-stroke-secondary h-px w-full" />

				<ScrollArea className="max-h-[calc(100vh_-_152px)] h-full">
					<div className="flex  w-full flex-col gap-8 p-8">
						<div className="flex w-full flex-col gap-2">
							<div className="flex flex-row items-center gap-4">
								<ToggleSwitch
									toggle={!mediaKit.isPublic}
									onToggle={() => {
										toggleMediaKitLocked.mutate(undefined, {
											onSuccess: () => {
												utils.mediaKit.invalidate();
											},
										});
									}}
								/>
								<div className="flex items-center gap-2">
									<Label size="sm" variant="paragraph" color="secondary">
										Lock Media Kit
									</Label>
								</div>
							</div>

							{!mediaKit.isPublic ? (
								<div className="flex flex-col gap-4">
									<p className="text-paragraph-sm text-text-secondary font-repro">
										Viewers must submit a request to access this Media Kit.
									</p>

									<div className="bg-surface-primary  border-stroke-tertiary flex cursor-pointer items-center justify-between rounded-md border p-4 ease-in-out">
										<div className="flex flex-col gap-1">
											<Label variant="overline" color="secondary" size="2xs">
												Private Link
											</Label>
											<p className="font-repro text-paragraph-xs">
												{getBioDomain()}/{creatorProfile?.username}/?code=
												{mediaKit.accessCode}
											</p>
										</div>

										<IconButton
											icon={isPrivateLinkCopied ? Check : CopySimple}
											variant="secondary"
											onClick={() => {
												navigator.clipboard.writeText(
													`https://${getBioDomain()}/${creatorProfile?.username}/?code=${
														mediaKit.accessCode
													}`,
												);

												setIsPrivateLinkCopied(() => true);

												setTimeout(() => {
													setIsPrivateLinkCopied(() => false);
												}, 1000);
											}}
										/>
									</div>
								</div>
							) : null}
						</div>

						<InfoBlocksEditor
							mediaKit={mediaKit}
							setActiveEditor={setActiveEditor}
							isAgency={agencyProfile ? true : false}
						/>

						<PlatformBlocksEditor
							isAgency={agencyProfile ? true : false}
							mediaKit={mediaKit}
							setActiveEditor={setActiveEditor}
							setActivePlatform={setActivePlatform}
						/>

						<CustomProfilesSection mediaKit={mediaKit} />
					</div>

					{!agencyProfile && 
						mediaKit?.blocks.map((block) => block.type).filter((type: string) => supportedPlatforms.includes(type)).length === 0 && (
						<div className='mx-8 px-4 py-12 border border-brand bg-sky-alpha-3 rounded-2xl flex flex-col items-center gap-4'>
							<p>Connect your social media profiles to get started.</p>
							<Button
								size="md"
								variant="primary"
								onClick={() => router.push(router.pathname,{ query: { settings: 'profiles' } }, { shallow: true })}
								className='whitespace-nowrap'
							>
								<Plugs /> Connect profiles
							</Button>
						</div>
					)}
				</ScrollArea>

				{!agencyProfile && (
					<div className='px-8 py-7 border-t border-stroke-tertiary flex'>
						<Button
							size="md"
							variant="secondary"
							onClick={() => copyTextToClipboard(`https://${url}`)}
							className='flex-1'
						>
							Copy link {hasCopied ? <Check /> : <CopySimple />}
						</Button>
					</div>
				)}
			</div>
		);
	}
};

const CustomProfilesSection = ({ mediaKit }: { mediaKit: MediaKit }) => {
	if (mediaKit.customProfiles.length === 0) {
		return null;
	}
	return (
		<div className="flex flex-col gap-2">
			<Label
				size="xs"
				color="secondary"
				variant="overline"
				tooltip="To edit or add custom profiles click on the talent from the talent dashboard."
			>
				Custom Profiles
			</Label>

			<div className="flex flex-col gap-2">
				{mediaKit.customProfiles.map((profile) => (
					<CustomProfileCard key={profile.uuid} profile={profile} />
				))}
			</div>
		</div>
	);
};

const CustomProfileCard = ({ profile }: { profile: CustomProfile }) => {
	return (
		<div className="bg-surface-hover-1 flex h-12 items-center justify-between rounded-lg p-4 ease-in-out">
			<div className="flex items-center">
				<div className="flex flex-col">
					<p className="text-button-xs font-repro h-[14px]">{profile.title}</p>
					<p className="text-paragraph-xs text-text-secondary font-repro">
						{profile.username}
					</p>
				</div>
			</div>
		</div>
	);
};
