import { ArrowLeft } from "@withjuly/julycons";
import { useCreator } from "~/utils/context/creator";
import { useAgency } from "~/utils/context/agency";
import { trpc } from "~/components/Utility/trpc";
import { useState } from "react";
import { ActiveEditorType } from "~/pages/creator/mediakit";
import { IconButton, TagInput } from "@withjuly/solisv2";

interface CastingTagsProps {
	onClickBack: (editor: ActiveEditorType) => void;
}

export const CastingTagsEditor: React.FC<CastingTagsProps> = ({
	onClickBack,
}) => {
	const creator = useCreator();
	const agency = useAgency();
	const utils = trpc.useContext();
	const updateHiddenTags = trpc.agency.updateHiddenTags.useMutation({
		onError: () => {
			utils.agency.get.invalidate();
		},
	});

	const [tags, setTags] = useState(
		agency.agencyProfile?.creators.find(
			(c) => c.uuid === creator.creatorProfile?.uuid,
		)?.hiddenTags === ""
			? []
			: agency.agencyProfile?.creators
					.find((c) => c.uuid === creator.creatorProfile?.uuid)
					?.hiddenTags.split(",") ?? [],
	);

	return (
		<div className="flex w-full flex-col rounded-lg">
			<div className="flex w-full items-center gap-4 px-8 py-7">
				<IconButton
					icon={ArrowLeft}
					size="sm"
					variant="secondary"
					onClick={() => {
						utils.agency.get.invalidate();
						onClickBack("none");
					}}
				/>
				<p className="text-header-xl font-repro">Casting Tags</p>
			</div>
			<div className="bg-stroke-secondary h-px w-full" />

			<div className="flex h-full max-h-[calc(100vh_-_152px)] flex-col gap-6 p-8">
				<TagInput
					label="Casting tags"
					placeholder="Enter tag here"
					tags={tags}
					onTagAdd={(tag) => {
						setTags([...tags, tag]);
						updateHiddenTags.mutate({
							creatorProfileUuid: creator?.creatorProfile?.uuid ?? "",
							tags: [...tags, tag].join(","),
						});
					}}
					onTagRemove={(tag) => {
						const newTags = tags.filter((t) => t !== tag);
						setTags(newTags);
						updateHiddenTags.mutate({
							creatorProfileUuid: creator?.creatorProfile?.uuid ?? "",
							tags: newTags.join(","),
						});
					}}
				/>
			</div>
		</div>
	);
};
