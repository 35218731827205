import {
	InstagramLogo,
	TiktokLogo,
	TwitchLogo,
	YoutubeLogo,
	FacebookLogo,
} from "@withjuly/julycons";

export const FacebookData = {
	icon: FacebookLogo,
	name: "Facebook",
};

export const InstagramData = {
	icon: InstagramLogo,
	name: "Instagram",
};

export const TikTokData = {
	icon: TiktokLogo,
	name: "TikTok",
};

export const YouTubeData = {
	icon: YoutubeLogo,
	name: "YouTube",
};

export const TwitchData = {
	icon: TwitchLogo,
	name: "Twitch",
};

export const PlatformData = {
	facebook: FacebookData,
	instagram: InstagramData,
	tiktok: TikTokData,
	youtube: YouTubeData,
	twitch: TwitchData,
} as const;
