import {
	MediaKit,
	MediaKitRatesBlock,
	ReorderMediaKitCollab,
	ReorderMediaKitRates,
	UpdateMediaKit,
	UpdateMediaKitBlock,
	MediaKitCollabsBlock,
} from "@withjuly/fabric";
import { useMutation } from "@tanstack/react-query";
import debounce from "lodash.debounce";
import { useCallback, useState } from "react";
import { trpc } from "~/components/Utility/trpc";
import posthog from "posthog-js";
import { useCreator } from "~/utils/context/creator";

export const useUpdateMediaKit = (delay = 1000, onSuccess?: () => void) => {
	const utils = trpc.useContext();
	const [isDebouncing, setIsDebouncing] = useState(false);
	const updateMediaKit = trpc.mediaKit.update.useMutation();
	const { setQueuedOperations } = useCreator();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debounced = useCallback(
		debounce(async (input: UpdateMediaKit) => {
			await updateMediaKit.mutateAsync(input, {
				onSuccess: onSuccess,
			});
			setIsDebouncing(false);

			posthog.capture("Update Media Kit");
		}, delay),
		[],
	);

	const mutation = useMutation(
		async (input: UpdateMediaKit) => {
			setIsDebouncing(true);
			debounced(input);

			setQueuedOperations((data) => ({
				...data,
				"mediaKit.update": debounced,
			}));
		},
		{
			onMutate: async (input) => {
				utils.mediaKit.get.setData(undefined, (previousMediaKit) => {
					return Object.assign({}, previousMediaKit, input);
				});
			},
		},
	);

	return {
		...mutation,
		isDebouncing,
	};
};

export const useUpdateMediaKitBlock = (debounceDuration = 1000) => {
	const utils = trpc.useContext();
	const [isDebouncing, setIsDebouncing] = useState(false);
	const updateMediaKitBlock = trpc.mediaKit.updateBlock.useMutation();
	const { setQueuedOperations } = useCreator();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debounced = useCallback(
		debounce(
			async ({ uuid, data }: { uuid: string; data: UpdateMediaKitBlock }) => {
				await updateMediaKitBlock.mutateAsync({ uuid, ...data });
				setIsDebouncing(false);

				posthog.capture("Update Media Kit Block", {
					type: data.type,
				});
			},
			debounceDuration,
		),
		[],
	);

	const mutation = useMutation(
		async (props: { uuid: string; data: UpdateMediaKitBlock }) => {
			setIsDebouncing(true);
			const result = debounced(props);
			setQueuedOperations((data) => ({
				...data,
				"mediaKit.updateBlock": debounced,
			}));
			return result;
		},
		{
			onMutate: async ({ uuid, data }) => {
				await utils.mediaKit.get.cancel();

				utils.mediaKit.get.setData(undefined, (previousMediaKit) => {
					if (previousMediaKit) {
						const newMediaKit: MediaKit = structuredClone(previousMediaKit);

						const block = newMediaKit.blocks.find(
							(block) => block.uuid === uuid,
						);
						if (
							block?.type === "instagram" ||
							block?.type === "tiktok" ||
							block?.type === "facebook" ||
							block?.type === "youtube" ||
							block?.type === "twitch"
						) {
							block.enabled = data.enabled ?? block.enabled;

							block.fields = {
								...block.fields,
								stats: block.fields.stats.map((stat) => ({
									...stat,
									enabled:
										data.fields?.stats.find((s) => stat.name === s.name)
											?.enabled ?? stat.enabled,
								})),
								recentPosts: {
									...block.fields.recentPosts,
									enabled:
										data.fields?.recentPosts?.enabled ??
										block.fields.recentPosts.enabled,
								},
								customStats: block.fields.customStats?.map((stat) => ({
									...stat,
									enabled:
										data.fields?.customStats?.find((s) => stat.name === s.name)
											?.enabled ?? stat.enabled,
								})),
							};

							const platformBlocks = newMediaKit.blocks.filter(
								(b) =>
									b.type === "youtube" ||
									b.type === "tiktok" ||
									b.type === "instagram" ||
									b.type === "facebook" ||
									b.type === "twitch",
							);
							const oldIndex = platformBlocks.findIndex((b) => b.uuid === uuid);
							platformBlocks.splice(oldIndex, 1);
							platformBlocks.splice(data.order ?? block.order, 0, block);
							platformBlocks.forEach((b, i) => (b.order = i));
							newMediaKit.blocks = newMediaKit.blocks.sort(
								(a, b) => a.order - b.order,
							);
						} else if (block?.type === "collabs" || block?.type === "rates") {
							block.enabled = data.enabled ?? block.enabled;
						}

						newMediaKit.nonce = Date.now().toString();

						return newMediaKit;
					}

					return previousMediaKit;
				});
			},
		},
	);

	return {
		...mutation,
		isDebouncing,
	};
};

export const useUpdateMediaKitRatesOrder = (debounceDuration = 1000) => {
	const utils = trpc.useContext();
	const [isDebouncing, setIsDebouncing] = useState(false);
	const updateMediaKitRatesOrder = trpc.mediaKit.reorderRate.useMutation();
	const { setQueuedOperations } = useCreator();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debounced = useCallback(
		debounce(async ({ uuid, order }: ReorderMediaKitRates) => {
			await updateMediaKitRatesOrder.mutateAsync({ uuid, order });
			setIsDebouncing(false);
		}, debounceDuration),
		[],
	);

	const mutation = useMutation(
		async ({ uuid, order }: ReorderMediaKitRates) => {
			setIsDebouncing(true);
			const result = debounced({ uuid, order });
			setQueuedOperations((data) => ({
				...data,
				"mediaKit.reorderRate": debounced,
			}));
			return result;
		},
		{
			onMutate: async ({ uuid, order }) => {
				await utils.mediaKit.get.cancel();

				utils.mediaKit.get.setData(undefined, (previousMediaKit) => {
					if (previousMediaKit) {
						const newMediaKit: MediaKit = structuredClone(previousMediaKit);
						const ratesBlock = newMediaKit.blocks.find(
							(block) => block.type === "rates",
						) as MediaKitRatesBlock | undefined;

						if (ratesBlock) {
							const rate = ratesBlock.rates.find((rate) => rate.uuid === uuid);
							if (rate) {
								const oldIndex = ratesBlock.rates.findIndex(
									(r) => r.uuid === uuid,
								);
								ratesBlock.rates.splice(oldIndex, 1);
								ratesBlock.rates.splice(order, 0, rate);
								ratesBlock.rates.forEach((rate, index) => {
									rate.order = index;
								});
								ratesBlock.rates = ratesBlock.rates.sort(
									(a, b) => a.order - b.order,
								);

								const newBlocks = newMediaKit.blocks.map((block) => {
									if (block.type === "rates") {
										return ratesBlock;
									} else {
										return block;
									}
								});

								newMediaKit.blocks = newBlocks;
								newMediaKit.nonce = Date.now().toString();

								return newMediaKit;
							}
						}
					}

					return previousMediaKit;
				});
			},
		},
	);

	return {
		...mutation,
		isDebouncing,
	};
};

export const useUpdateMediaKitCollabOrder = (debounceDuration = 1000) => {
	const utils = trpc.useContext();
	const [isDebouncing, setIsDebouncing] = useState(false);
	const updateMediaKitCollabsOrder = trpc.mediaKit.reorderCollab.useMutation();
	const { setQueuedOperations } = useCreator();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debounced = useCallback(
		debounce(async ({ uuid, order }: ReorderMediaKitCollab) => {
			await updateMediaKitCollabsOrder.mutateAsync({ uuid, order });
			setIsDebouncing(false);
		}, debounceDuration),
		[],
	);

	const mutation = useMutation(
		async ({ uuid, order }: ReorderMediaKitCollab) => {
			setIsDebouncing(true);
			const result = debounced({ uuid, order });
			setQueuedOperations((data) => ({
				...data,
				"mediaKit.reorderCollab": debounced,
			}));
			return result;
		},
		{
			onMutate: async ({ uuid, order }) => {
				await utils.mediaKit.get.cancel();

				utils.mediaKit.get.setData(undefined, (previousMediaKit) => {
					if (previousMediaKit) {
						const newMediaKit: MediaKit = structuredClone(previousMediaKit);
						const collabsBlock = newMediaKit.blocks.find(
							(block) => block.type === "collabs",
						) as MediaKitCollabsBlock | undefined;

						if (collabsBlock) {
							const collab = collabsBlock.collabs.find(
								(collab) => collab.uuid === uuid,
							);
							if (collab) {
								const oldIndex = collabsBlock.collabs.findIndex(
									(c) => c.uuid === uuid,
								);
								collabsBlock.collabs.splice(oldIndex, 1);
								collabsBlock.collabs.splice(order, 0, collab);
								collabsBlock.collabs.forEach((collab, index) => {
									collab.order = index;
								});
								collabsBlock.collabs = collabsBlock.collabs.sort(
									(a, b) => a.order - b.order,
								);

								const newBlocks = newMediaKit.blocks.map((block) => {
									if (block.type === "collabs") {
										return collabsBlock;
									} else {
										return block;
									}
								});

								newMediaKit.blocks = newBlocks;
								newMediaKit.nonce = Date.now().toString();

								return newMediaKit;
							}
						}
					}

					return previousMediaKit;
				});
			},
		},
	);

	return {
		...mutation,
		isDebouncing,
	};
};
