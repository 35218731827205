import { MediaKit } from "@withjuly/fabric";
import { ActiveEditorType } from "~/pages/creator/mediakit";
import {
	useUpdateMediaKit,
	useUpdateMediaKitBlock,
} from "~/utils/api/query/mediakit";
import { CaretRight } from "@withjuly/julycons/bold";
import { Label, ToggleSwitch } from "@withjuly/solisv2";

interface InfoBlocksEditorProps {
	mediaKit: MediaKit;
	setActiveEditor: (editor: ActiveEditorType) => void;
	isAgency: boolean;
}

export const InfoBlocksEditor: React.FC<InfoBlocksEditorProps> = ({
	mediaKit,
	setActiveEditor,
	isAgency,
}) => {
	const updateMediaKit = useUpdateMediaKit();
	const updateBlock = useUpdateMediaKitBlock();

	const collabsBlock = mediaKit?.blocks.find(
		(block) => block.type === "collabs",
	);
	const ratesBlock = mediaKit?.blocks.find((block) => block.type === "rates");

	return (
		<div className="flex flex-col gap-2">
			<Label size="xs" color="secondary" variant="overline">
				Info
			</Label>
			<div className="flex flex-col gap-2">
				<div
					role="button"
					aria-label="Edit about me"
					className="bg-surface-hover-1 hover:bg-surface-hover-2 flex h-12 cursor-pointer items-center justify-between rounded-lg p-4 ease-in-out"
					onClick={() => setActiveEditor("header")}
				>
					<p className="paragraph-sm font-repro">Header Section</p>

					<CaretRight className="text-text-tertiary" />
				</div>

				{isAgency ? (
					<div
						role="button"
						aria-label="Edit about me"
						className="bg-surface-hover-1 hover:bg-surface-hover-2 flex h-12 cursor-pointer items-center justify-between rounded-lg p-4 ease-in-out"
						onClick={() => setActiveEditor("casting-tags")}
					>
						<p className="paragraph-sm font-repro">Casting Tags</p>

						<CaretRight className="text-text-tertiary" />
					</div>
				) : null}

				<div
					role="button"
					aria-label="Edit about me"
					className="bg-surface-hover-1 hover:bg-surface-hover-2 flex h-12 cursor-pointer items-center justify-between rounded-lg p-4 ease-in-out"
					onClick={() => setActiveEditor("about")}
				>
					<p className="paragraph-sm font-repro">About</p>

					<div className="flex items-center gap-4">
						<div className="flex" onClick={(e) => e.stopPropagation()}>
							<ToggleSwitch
								toggle={mediaKit.isAboutEnabled}
								onToggle={() => {
									updateMediaKit.mutate({
										...mediaKit,
										isAboutEnabled: !mediaKit.isAboutEnabled,
									});
								}}
							/>
						</div>
						<CaretRight className="text-text-tertiary" />
					</div>
				</div>

				{collabsBlock?.type == "collabs" && collabsBlock ? (
					<div
						role="button"
						aria-label="Edit previous collaborations"
						className="bg-surface-hover-1 hover:bg-surface-hover-2 flex h-12 cursor-pointer items-center justify-between rounded-lg p-4 ease-in-out"
						onClick={() => setActiveEditor("collabs")}
					>
						<p className="paragraph-sm font-repro">Previous Collaborations</p>

						<div className="flex items-center gap-4">
							<div className="flex" onClick={(e) => e.stopPropagation()}>
								<ToggleSwitch
									toggle={collabsBlock.enabled}
									onToggle={() => {
										updateBlock.mutate({
											uuid: collabsBlock.uuid,
											data: {
												type: collabsBlock.type,
												enabled: !collabsBlock.enabled,
											},
										});
									}}
								/>
							</div>
							<CaretRight className="text-text-tertiary" />
						</div>
					</div>
				) : null}

				{ratesBlock ? (
					<div
						role="button"
						aria-label="Edit rates"
						className="bg-surface-hover-1 hover:bg-surface-hover-2 flex h-12 cursor-pointer items-center justify-between rounded-lg p-4 ease-in-out"
						onClick={() => setActiveEditor("rates")}
					>
						<p className="paragraph-sm font-repro">Rates</p>

						<div className="flex items-center gap-4">
							<div className="flex" onClick={(e) => e.stopPropagation()}>
								<ToggleSwitch
									toggle={ratesBlock.enabled}
									onToggle={() => {
										updateBlock.mutate({
											uuid: ratesBlock.uuid,
											data: {
												type: ratesBlock.type,
												enabled: !ratesBlock.enabled,
											},
										});
									}}
								/>
							</div>
							<CaretRight className="text-text-tertiary" />
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};
